@import url(https://use.typekit.net/mpw5dkx.css);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;1,600;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.card {
    margin: 0px 15px ;
    border-radius:10px;
    background:"#F7F7F7";
    width: 94%;
    border: "1px solid #FFB532";
  }

.MuiTypography-root{
    font-size: 10px;
}

.disabled_row{
  opacity: 0.4;
}

.dropdownIcon{
  color:#FFFFFF 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.dropdownIcon:hover{
		color: white;
    cursor: pointer;
    opacity: 1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  border-radius: 5px; 
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

.MuiDialog-paper{
    border-radius: 10px !important;
}

.card_alert {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiDialog-paperWidthSm {
    width: 500px !important;
    border-radius: 12px !important;
    background: #F6F6F6 0% 0% no-repeat padding-box !important;
}

.modalTitle {
    text-align: center;
    font-family: muli, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    color: #025BA5;
    opacity: 1;
}

.MuiButton-root {
    color: white !important;
    padding: 6px 16px !important;
    font-size: 0.875rem !important;
    min-width: 64px !important;
    box-sizing: border-box !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    font-family: Muli, Arial, sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75;
    border-radius: 4px !important;
    text-transform: capitalize !important;
    text-align: center !important;
    /*background: #16B1F3 0% 0% no-repeat padding-box !important;*/
}

.MuiDialogActions-root {
    flex: 0 0 auto !important;
    display: flex !important;
    padding: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px;
    flex-direction: column !important;
}

.contentTextField {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listEmpresa {
    width: 100% !important;
    height: 360px;
}

.MuiPaper-elevation0 {
    box-shadow: none;
    background: #F6F6F6 !important;
}

.messageEnterprise {
    color: #025BA5;
    padding-top: 14px;
}

hr {
    border: 1px solid #DADADA;
    background-color: #DADADA;
}



.login {
    height: 98vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recaptcha{
    margin: 12px 9px;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100% !important;
    overflow-x: hidden !important;
}

@media(min-width: 700px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}

@media(min-width: 250px) {
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;
    }
}
